/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { getDashboardData_getDashboardData_salesOutcome as SalesOutComeData } from '../../../gql/types';
import { report_report as ReportResponse } from '../../../gql/types';
import RepComparisonSalesOutcome from '../RepComparisonSalesOutcome';

interface ReportSalesOutcomeWidgetProps {
  data1?: SalesOutComeData | null;
  data2?: SalesOutComeData | null;
  reportData?: ReportResponse | null;
  reportData1?: ReportResponse | null;
}
const useStyles = makeStyles({
  page6MainComponent: {
    marginTop: 8,
    marginLeft: -25,
  },
  page6NextComponent: {
    marginTop: 8,
    marginLeft: -25,
  },
});

const ReportSalesOutcomeWidget: React.FC<ReportSalesOutcomeWidgetProps> = ({
  data1,
  data2,
  reportData,
  reportData1,
}: ReportSalesOutcomeWidgetProps) => {

  const page6SalesOutcomeData = [
    {
      type: 'percentage',
      headingName: 'Quota Attainment (%)',
      firstPersonTotalValue: data1?.quotaAttainment && data1?.quotaAttainment,
      secondPersonTotalValue: data2?.quotaAttainment && data2?.quotaAttainment,
      avgValue: reportData?.quotaAttainment?.avgQuotaAttainment,
      maxValue: reportData?.quotaAttainment?.maxQuotaAttainment,
      minValue: reportData?.quotaAttainment?.minQuotaAttainment,
    },
    {
      type: 'amount',
      headingName: 'Total NACV ($ 000)',
      firstPersonTotalValue: data1?.totalSalesClosed?.overallTotalSalesClosed,
      secondPersonTotalValue: data2?.totalSalesClosed?.overallTotalSalesClosed,
      avgValue: reportData?.salesClosed?.avgSalesClosed,
      maxValue: reportData?.salesClosed?.maxSalesClosed,
      minValue: reportData?.salesClosed?.minSalesClosed,
    },
    {
      type: 'amount',
      headingName: 'Land ACV ($ 000)',
      firstPersonTotalValue: data1?.totalSalesClosed?.bookingAmount,
      secondPersonTotalValue: data2?.totalSalesClosed?.bookingAmount,
      avgValue: reportData?.bookings?.avgBookings,
      maxValue: reportData?.bookings?.maxBookings,
      minValue: reportData?.bookings?.minBookings,
    },
    {
      type: 'amount',
      headingName: 'Expand ACV ($ 000)',
      firstPersonTotalValue: data1?.totalSalesClosed?.renewalAmount,
      secondPersonTotalValue: data2?.totalSalesClosed?.renewalAmount,
      avgValue: reportData?.renewal?.avgRenewal,
      maxValue: reportData?.renewal?.maxRenewal,
      minValue: reportData?.renewal?.minRenewal,
    },
    {
      type: 'dealsClosed',
      headingName: 'Deals Closed (#)',
      firstPersonTotalValue: data1?.noOfDeals,
      secondPersonTotalValue: data2?.noOfDeals,
      avgValue: reportData?.dealsClosed?.avgDealsClosed,
      maxValue: reportData?.dealsClosed?.maxDealsClosed,
      minValue: reportData?.dealsClosed?.minDealsClosed,
    },
    {
      type: 'dealSizeAmount',
      headingName: 'Deal Size ($ 000)',
      firstPersonTotalValue:
        data1?.totalSalesClosed?.overallTotalSalesClosed &&
        data1?.noOfDeals &&
        Math.round(
          data1?.totalSalesClosed?.overallTotalSalesClosed / data1?.noOfDeals,
        ),
      secondPersonTotalValue:
        data2?.totalSalesClosed?.overallTotalSalesClosed &&
        data2?.noOfDeals &&
        Math.round(
          data2?.totalSalesClosed?.overallTotalSalesClosed / data2?.noOfDeals,
        ),
      maxValue: reportData?.dealSize?.maxDealSize,
      minValue: reportData?.dealSize?.minDealSize,
      avgValue: reportData?.dealSize?.avgDealSize,
    },
    {
      type: 'winRatePercentage',
      headingName: 'Win Rate (%)',
      firstPersonTotalValue: data1?.winRate && data1?.winRate,
      secondPersonTotalValue: data2?.winRate && data2?.winRate,
      avgValue: reportData?.winRate?.avgWinRate,
      maxValue: reportData?.winRate?.maxWinRate,
      minValue: reportData?.winRate?.minWinRate,
    },
    // {
    //   type: 'days',
    //   headingName: 'Sales Cycle (Days)',
    //   firstPersonTotalValue: reportData?.salesCycle?.totalSalesCycle,
    //   secondPersonTotalValue: reportData1?.salesCycle?.totalSalesCycle,
    //   avgValue: reportData?.salesCycle?.avgSalesCycle,
    //   maxValue: reportData?.salesCycle?.maxSalesCycle,
    //   minValue: reportData?.salesCycle?.minSalesCycle,
    // },
  ];

  const classes = useStyles();

  return (
    <Grid container justify="space-between" direction="column">
      {page6SalesOutcomeData.map((item, index) => (
        <Grid
          item
          className={
            index === 0
              ? classes.page6MainComponent
              : classes.page6NextComponent
          }
          key={index}
        >
          <RepComparisonSalesOutcome
            type={item.type}
            firstPersonValue={item.firstPersonTotalValue}
            secondPersonValue={item.secondPersonTotalValue}
            headingName={item.headingName}
            maxValue={item.maxValue}
            minValue={item.minValue}
            avgValue={item.avgValue}
            index={index}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ReportSalesOutcomeWidget;
