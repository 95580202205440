import { COLORS } from '../plTheme';

export const roundNumber = (num: string) => {
  const parts = num.split(',');
  return parts.length > 1
    ? (
      Math.round(
        (parseInt(parts.join(''), 10) / Math.pow(1000, parts.length - 1)) *
        10,
      ) / 10
    )?.toString() + ['K', 'M', 'B'][parts.length - 2]
    : parts[0];
};

export const ProductColorMapping: { name: string; color: string }[] = [
  {
    name: 'Prod1',
    color: '#366FF5',
  },
  {
    name: 'Prod2',
    color: '#0B69FF',
  },
  {
    name: 'Prod3',
    color: '#5BBFF9',
  },
];

export  const formatHireDate = (date: string  | undefined) => {
  if (date) {
    let formatedDate = date?.includes("-") ? date : +date
    const dateObject = new Date(formatedDate);
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const year = dateObject.getFullYear() % 100;
    return `(DoH: ${month}/${year})`;
  } else return '';
};
