import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { COLORS, plTheme } from '../../../plTheme';

import { _t_ } from '../../../utils/translation/translation';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import RepProgressBar from '../RepProgressBar';

interface ReportSalesActivityProps {
  value: number;
  benchmarkValue: number;
  minValue: number;
  maxValue: number;
}

const ReportSalesActivity: React.FC<ReportSalesActivityProps> = ({
  value,
  benchmarkValue,
  minValue,
  maxValue,
}: ReportSalesActivityProps) => {
  const barLength = maxValue;
  const driverAvg = benchmarkValue;
  const avgPercentage = (driverAvg / barLength) * 100;
  const percentage = (value / barLength) * 100;
  const useStyles = makeStyles({
    gridExample: {
      width: '150px',
    },
    innerSubHeading: {
      marginTop: '-7px',
      color: COLORS.GREY_TEXT_DARKER,
      width: '150px',
    },
    footerArrow: {
      height: '25px',
      width: '25px',
      marginLeft: `${avgPercentage - 5}%`,
    },
    footer: {
      marginLeft: `${avgPercentage - 32}%`,
      marginTop: '-10px',
      marginBottom: '13px',
      width: '150px',
    },
    footerTypography: {
      fontWeight: plTheme.typography.h4.fontWeight,
      fontSize: '10px',
    },
    dataValue: {
      width: '150px',
      marginTop: '0px',
      marginLeft: `${percentage - 10}%`,
    },
  });

  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Grid item className={classes.gridExample}>
        <Typography variant="h6" className={classes.dataValue}>
          {value}
        </Typography>
      </Grid>
      <Grid item>
        <RepProgressBar
          value={value}
          type="salesActivity"
          maxValue={barLength}
          minValue={minValue}
        />
      </Grid>
      <Grid item className={classes.innerSubHeading}>
        <ArrowDropUpIcon className={classes.footerArrow} />
      </Grid>
      <Grid item className={classes.footer}>
        <Typography
          variant="h6"
          className={classes.footerTypography}
          color="textSecondary"
        >
          {_t_('BENCHMARK')}: {benchmarkValue}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ReportSalesActivity;
