import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { getDashboardData_peopleActivities as SalesActivitiesData } from '../../../gql/types';
import RepComparisonSalesActivities from '../RepComparisonSalesActivities';
import { COLORS } from '../../../plTheme';

interface ReportSalesActivitiesWidgetProps {
  data1?: any | null;
  data2?: any | null;
}

const useStyles = makeStyles({
  root: {
    width: '92.5%',
    border: '1px solid rgba(215, 223, 233, 0.2)',
    position: 'relative',
    minHeight: '240px',
    alignItems: 'center',
    marginLeft: 28,
    backgroundColor: '#D9D9D9',
    fontSize: 16,
    color:'#1E1F20'
  },
  page6Component: {
    marginTop: 5,
    marginLeft: -25,
  },
  page6NextComponent: {
    marginTop: 8,
    marginLeft: -25,
  },
  keyDriver: {
    padding: 3,
    width: 104,
    marginLeft: 50,
    backgroundColor: '#0B69FF',
    borderRadius: '5px 5px 0 0',
    textAlign: 'center',
    position: 'absolute',
    marginTop: -25,
    fontSize: 15,
    fontFamily: 'Rubik',
    color: 'white',
    height: '25px',
    lineHeight: '20px',
  },
});
const ReportSalesActivitiesWidget: React.FC<ReportSalesActivitiesWidgetProps> = ({
  data1,
  data2,
}: ReportSalesActivitiesWidgetProps) => {
  const rep1_data = data1?.battleCardData;
  const rep2_data = data2?.battleCardData;
  const org_driver = rep1_data?.[0]?.original_top_driver;
  const org_driver_value1 = rep1_data?.[0]?.X_poor_org_driver_value;
  const org_driver_value2 = rep1_data?.[0]?.X_good_org_driver_value;
  const org_driver_avg = rep1_data?.[0]?.org_driver_mean_value;
  const classes = useStyles();
  const page6SalesActivitiesData = [
    {
      name: org_driver,
      firstPersonValue: org_driver_value1?.toFixed(1),
      secondPersonValue: org_driver_value2?.toFixed(1),
      benchmarkValue: org_driver_avg?.toFixed(1),
      minValue: data1?.org_driver_min_value?.toFixed(1),
      maxValue: data1?.org_driver_max_value?.toFixed(1),
    },
    {
      name: rep1_data?.[0]?.name,
      firstPersonValue: rep1_data?.[0]?.value?.toFixed(1),
      secondPersonValue: rep2_data?.[0]?.value?.toFixed(1),
      benchmarkValue: data1?.driver1_mean_value?.toFixed(1),
      minValue: data1?.driver1_min_value?.toFixed(1),
      maxValue: data1?.driver1_max_value?.toFixed(1),
    },
    {
      name: rep1_data?.[1]?.name,
      firstPersonValue: rep1_data?.[1]?.value?.toFixed(1),
      secondPersonValue: rep2_data?.[1]?.value?.toFixed(1),
      benchmarkValue: data1?.driver2_mean_value?.toFixed(1),
      minValue: data1?.driver2_min_value?.toFixed(1),
      maxValue: data1?.driver2_max_value?.toFixed(1),
    },
    {
      name: rep1_data?.[2]?.name,
      firstPersonValue: rep1_data?.[2]?.value?.toFixed(1),
      secondPersonValue: rep2_data?.[2]?.value?.toFixed(1),
      benchmarkValue: data1?.driver3_mean_value?.toFixed(1),
      minValue: data1?.driver3_min_value?.toFixed(1),
      maxValue: data1?.driver3_max_value?.toFixed(1),
    },
  ];

  return (
    <Grid container justify="space-between" direction="column">
      {!data1 ? (
        <Grid
          container
          direction="row"
          justify="space-around"
          className={classes.root}
        >
          New Hire - please compare with a tenured rep
        </Grid>
      ) : (
        page6SalesActivitiesData.map((item, index) => (
          <Grid
            item
            className={
              index === 0 ? classes.page6Component : classes.page6NextComponent
            }
            key={index}
          >
            {index === 0 && <div className={classes.keyDriver}>Org Driver</div>}
            <RepComparisonSalesActivities
              minValue={item?.minValue}
              maxValue={item?.maxValue}
              secondPersonValue={item.secondPersonValue}
              firstPersonValue={item.firstPersonValue}
              benchmarkValue={item.benchmarkValue}
              headingName={item.name}
              index={index}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default ReportSalesActivitiesWidget;
